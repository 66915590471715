@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&family=Roboto:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Roboto:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Roboto:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto:ital@1&display=swap");
body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: #ffffff;
}

/* Header style */
.header-body {
  height: 80px;
  width: 100%;
  z-index: 99;
  position: fixed;
  background-color: rgb(255, 255, 255);
}
.headerScroll {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
}

.headerScroll::after {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
}

.header-nav {
  max-width: 1400px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.logo-body {
  height: 32px;
  margin-top: 25px;
  z-index: 100;
}

.logo-body img {
  height: 100%;
}

.middle-links,
.end-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle-links a,
.end-links a,
.toggleLi .community,
.toggleLi .join {
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto";
  margin: 0px 15px;
  font-weight: 400;
  color: #484848;
}

.middle-links a:hover {
  color: #000000;
  transition: ease-in 0.2;
  font-weight: 500;
}

.middle-links a:before {
  transition: ease-in 0.2;
}

.middle-links a:hover:after {
  transition: ease-in 0.2;
}

.middle-links .active {
  color: #403bf1;
  font-weight: 500;
  transition: ease-in 0.2;
}

.middle-links a {
  padding: 35px 5px;
  display: inline-block;
  position: relative;
}

.middle-links a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  border-radius: 10px;
  bottom: 0;
  margin-bottom: 4px;
  left: 0;
  background-color: #403bf1;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.middle-links .active {
  color: #403bf1;
}
/* .middle-links a:focus:after, */
.middle-links .active:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.end-links a,
.toggleLi .join,
.toggleLi .community {
  padding: 12px 30px;
  border-radius: 5px;
}
.toggleLi .join,
.toggleLi .community,
.toggleLi .invest,
.toggleLi .raise {
  width: 100%;
  text-align: center;
}

.end-links .community,
.toggleLi .community {
  background-color: #edeef0;
  transition: ease-in-out 0.3 background-color;
}

.end-links .community:hover {
  background-color: #e7e9ec;
  transition: ease-in-out 0.3 background-color;
  color: #000000;
}

.end-links .join,
.toggleLi .join {
  background-color: #000000;
  color: white;
  font-weight: 400;
}

/*  */
.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: none;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background: #fafaff;
  z-index: 98;
  outline: none;
}
.toggleBtn:focus {
  outline: none;
}
.nav-container {
  padding: 0px 25px;
}

.toggleBtn {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  z-index: 201;
}

.toggleUl,
.toggleLi {
  margin: 0;
  padding: 0;
  z-index: 99;
}

.toggleUl {
  position: absolute;
  top: 90px;
  width: 100%;
}

.toggleLi {
  margin: auto;
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  margin-top: 0px;
  align-items: center;
  max-width: 500px;
  padding: 0px;
}

@media (max-width: 750px) {
  .header-nav {
    padding: 0 20px;
  }
  .middle-links a,
  .end-links a {
    margin: 0px 10px;
  }
}

@media (max-width: 700px) {
  .middle-links,
  .end-links {
    display: none;
  }
  .sidebar {
    display: block;
  }
  .toggleLi .community,
  .toggleLi .join {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .toggleLi a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/*  */
/*  */
/*  */
/*  */
/* Home page design */

.hero-body {
  position: relative;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.main-container {
  position: relative;
  display: flex;
  z-index: 66;
}

.animated-number {
  font-size: 35px;
  font-weight: 700;
  color: #332eef;
}
